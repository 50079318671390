$(document).ready(function () {
    $(".jqChangeFilters").on("click", performFilter);

    if (urlParam("department") !== null && urlParam("location") !== null) {
        updateFilterDropdownWithParam();
    }

    function performFilter() {
        var currentURL = window.location.href; //CURRENT LOCATION
        var currentValue = $(".jqDepartmentFilter").val(); //SELECT DROPDOWN VALUE FOR DEPARTMENT
        var currentLocation = $(".jqLocationFilter").val(); //SELECT DROPDOWN VALUE FOR LOCATION
        if (typeof currentValue && currentLocation === 'undefined') return;
        
        var newURL = updateQueryStringParameter(currentURL, 'department', currentValue); //UPDATING ONLY DEPARTMENT WITH NEWLY SELECTED VALUE
        newURL = updateQueryStringParameter(newURL, 'location', currentLocation); //UPDATING ONLY LOCATION WITH NEWLY SELECTED VALUE
    
        //console.log('currentURL = ', currentURL);
        //console.log('newURL = ', newURL);

        if (typeof newURL !== 'undefined' && newURL !== currentURL) {
            window.location.href = newURL;
        }    
    }

    function updateFilterDropdownWithParam() {

        var urlDepartment = urlParam("department");
        var urlLocation = urlParam("location");

        urlDepartment = urlDepartment.replace(/\+/g,' ');
        urlLocation = urlLocation.replace(/\+/g,' ');

        urlDepartment = decodeURIComponent(urlDepartment);
        urlLocation = decodeURIComponent(urlLocation);

        //console.log('urlDepartment = ', urlDepartment);
        //console.log('urlLocation = ', urlLocation);

        if (urlDepartment || urlLocation !== null && $(".jqDepartmentFilter,.jqLocationFilter").length > 0) {

            //console.log('setting jqDepartmentFilter to ', urlDepartment);
            //console.log('setting jqLocationFilter to ', urlLocation);

            $(".jqDepartmentFilter").val(urlDepartment);
            $(".jqLocationFilter").val(urlLocation);
        }
    }
  
    function urlParam(name) {
        var reg = new RegExp("[\\?&]" + name + "=([^&#]*)");
        var results = reg.exec(window.location.search);
        if (results) return decodeURI(results[1]) || null;
        else return null;
    }

    function updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
        if (value === undefined) {
            if (uri.match(re)) {
                return uri.replace(re, '$1$2');
            } else {
                return uri;
            }
        } else {
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            } else {
                var hash = '';
                if (uri.indexOf('#') !== -1) {
                    hash = uri.replace(/.*#/, '#');
                    uri = uri.replace(/#.*/, '');
                }
                var separator = uri.indexOf('?') !== -1 ? "&" : "?";
                return uri + separator + key + "=" + value + hash;
            }
        }
    }
});
