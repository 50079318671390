// Appius parallax background scrolling works by calculating using the maximum potential of each image to move in the provided area.
// To increase the speed movement a larger sized image will be required.

// 1440 x 600 // use size
var ELEMENT_SELECTOR = '.full-width--bg, .hero-banner';
var STARTING_POSITION = 60; // A value in % of where the image should be vertically positioned "at rest"
var STRENGTH = 20; // A value in % of how far the background should move off-centre (not to exceed 1 - starting position)
var elementArray = [];
var parallaxDisabled = false;

$(document).ready(function () {
    parallaxDisabled = checkIfDisabled();

    // Create element array cache on page load rather than per scroll
    if (parallaxDisabled === false) {
        createElementArrayCache();
        runParallax();
    }
});

$(window).resize(function () { if (parallaxDisabled === false) { resizeBounce(); }});
$(window).scroll(function () { if (parallaxDisabled === false) { runParallax(); }});

function checkIfDisabled() {
    return (typeof $('body').attr('data-parallax') !== "undefined" && $('body').attr('data-parallax') === "disabled" && $('body').hasClass('homepage'));
}

function parallaxWindowLoad() {
    if (parallaxDisabled === false) {
        createElementArrayCache();
        runParallax();
    }
}

window.addEventListener('load', parallaxWindowLoad, false);

// Debounce the resize viewport event to prevent performance hits.
var resizeBounce = debounce(function() {
    createElementArrayCache();
    runParallax();
}, 200);

function runParallax() {
    var scrollPos = $(window).scrollTop();
    updateBanners(scrollPos);
}

function createElementArrayCache() {
    elementArray = [];

    $(ELEMENT_SELECTOR).each(function () {
        var $this = $(this);

        var item = {
            elem : $this,
            elemTop : $this.offset().top,
            elemBottom : $this.outerHeight() + ($this.offset().top),
            elemMid :  ($this.outerHeight() / 2) + ($this.offset().top),
            elemHeight : $this.outerHeight()
        };
        elementArray.push(item);
    });
}

// Check if the bottom of the banner is below the top of the screen,
// and the top of the banner is above the bottom of the screen
function isInView(elem, scrollPos) {
    return (scrollPos + $(window).height() > elem.elemTop && scrollPos <= elem.elemBottom);
}

// Using the centre-line of the screen as the 50% point, set a % offset
// on the background image y position based on its relative viewport position
function calculateBackgroundOffset(elem, scrollPos) {
    var windowHeight = $(window).height();
    var startPosition = elem.elemTop - windowHeight;
    var mapped = ((scrollPos - startPosition) / (elem.elemHeight + windowHeight)) * -2 + 1; // Convert from 0 to 1, to -1 to +1
    var offset = STARTING_POSITION + mapped * STRENGTH;

    return offset;
}

// Update or transition the background image
function updateBackgroundOffset(elem, y) {
    var coords = '50% ' + y.toPrecision(2) + '%';
    elem.elem.css({ backgroundPosition: coords});
}

// Update the elements using element array as a store
function updateBanners(scrollPos) {
    for (var i = 0; i < elementArray.length; i++ ){
        var y = 0;
        if (isInView(elementArray[i], scrollPos)) {
            y = calculateBackgroundOffset(elementArray[i], scrollPos);
            updateBackgroundOffset(elementArray[i], y);
        }
    }
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
