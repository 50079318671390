$(document).ready(function() {
    var touchMoved;

    $('.mobile-nav__toggle').on('click', function (e) {
        $('.primary-nav').addClass('is-active');
        $('body').addClass('mobile-nav-open');
        e.stopPropagation();

        updateNavHeight();
    });

    $('.primary-nav').on('click tap', '.primary-nav__item.dropdown > .primary-nav__link', function (e) {
        if ($(window).innerWidth() < 768) {
            e.preventDefault();
            if (touchMoved === true) return; // If scrolling

            var $submenu = $(this).closest('.primary-nav__item.dropdown');

            if ($submenu.hasClass('is-active')) {
                var openItemCount = $('.primary-nav__item.dropdown.is-active').length;
                if (openItemCount <= 1) {
                    closeNav();
                }
            } else {
                $('.primary-nav').addClass('is-active');
                $('body').addClass('mobile-nav-open');
                $submenu.addClass('is-active');
            }
            updateNavHeight();
        }
    }).on('touchmove', function() { touchMoved = true; }).on('touchstart', function() { touchMoved = false; });

    $('.primary-nav_back').on('click', function() {
        $(this).closest('.primary-nav__item.dropdown.is-active').removeClass('is-active');
        updateNavHeight()
    });

    $('.primary-nav_btn-close, .primary-nav_overlay').on('click', function() {
        closeNav();
    });
});
var heightOnLoad = $(".primary-nav__list").innerHeight();
function updateNavHeight() {
    var newFullHeight = $("body").innerHeight()
    var newHeight = newFullHeight - $(".primary-nav_mobile-header").innerHeight();
    var visibleHeight = heightOnLoad > 100 ? heightOnLoad : $(".primary-nav__list").innerHeight();
    var currentOpenSubmenus = $('.primary-nav__item.dropdown.is-active').children('.primary-nav__panel').children('.primary-nav__item');
    currentOpenSubmenus.length && currentOpenSubmenus.each(function(index){
        $(currentOpenSubmenus[index]).css({ 'height': "auto"})
        visibleHeight = $(currentOpenSubmenus[currentOpenSubmenus.length-1]).innerHeight();
        $(currentOpenSubmenus[index]).css({ 'height': visibleHeight })
    });
    
	$('.mobile-nav__fullheight').css({ 'height': newHeight });
	$('.primary-nav_overlay').css({ 'height': newFullHeight });
    $('.primary-nav__list').css({ 'height': visibleHeight });
}

function closeNav() {
    $('.primary-nav, .primary-nav__item.dropdown').removeClass('is-active');
    setTimeout(function() {
        $('body').removeClass('mobile-nav-open');
    }, 600);
}
