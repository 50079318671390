var CAROUSEL_SELECTOR = '.carousel__slick';

$(document).ready(function() {
    $(CAROUSEL_SELECTOR).slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 10,
        draggable: true,
        autoplay: false,
        speed: 1200,
        lazyLoadBuffer: 1,
        dots: true
    });

    $('.carousel__toggle').on('click', onCarouselToggle);

    function onCarouselToggle() {
        var $this = $(this);
        var $slick = $this.next('.carousel__slick');
        var isToggled = $this.hasClass('is-toggled');

        if (isToggled) {
            $this.removeClass('is-toggled');
            //$this.text('Pause carousel');
            $this.attr('aria-pressed', 'false')
            $slick.slick('slickPlay');
        } else {
            $this.addClass('is-toggled');
            $this.attr('aria-pressed', 'true')
            //$this.text('Start carousel');
            $slick.slick('slickPause');
        }
    }

	// Create Live region to populate content of active slide
	var liveRegion = document.createElement('div');
	liveRegion.setAttribute('aria-live', 'polite');
	liveRegion.setAttribute('aria-atomic', 'true');
	liveRegion.setAttribute('class', 'liveRegion sr-only');

	$('.carousel').append(liveRegion);

    // Remove the automatic announcements built-in by Slick
	$('.carousel .slick-list').removeAttr('aria-live');

    // Allow all dots to be read on hover
    $('.slick-dots li').removeAttr('aria-hidden').removeAttr('aria-label');

    // Set the first dot item to be the current
    $('.slick-dots li').eq(0).find('button').attr('aria-label', '(1) Current')

    $(CAROUSEL_SELECTOR).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        updateSliderArrowVisibility(event, slick, nextSlide, $(this));
    });

    $(CAROUSEL_SELECTOR).on('afterChange', function (event, slick, currentSlide, nextSlide) {
        updateSliderArrowVisibility(event, slick, nextSlide, $(this));
        updateAriaElements($(this));
    });

    $(CAROUSEL_SELECTOR).on('focusin', function (e) {
        $(CAROUSEL_SELECTOR).slick('slickPause');
    });

    $(CAROUSEL_SELECTOR).on('focusout', function (e) {
        $(CAROUSEL_SELECTOR).slick('slickPlay');
    });

    $(CAROUSEL_SELECTOR).on('click', '.slick-next, .slick-prev', function () {
        $(CAROUSEL_SELECTOR).slick('slickPause');
    });

    $(CAROUSEL_SELECTOR).on('swipe', function () {
        $(CAROUSEL_SELECTOR).slick('slickPause');
    });

    function updateAriaElements($slick) {

        if ($slick.length === 0) return false;

        var slickInstance = $slick[0].slick;
        var currentSlide = slickInstance.currentSlide

        var newContent = 'Slide ' + (currentSlide + 1) + ' of ' + slickInstance.slideCount;

        // Update Live Region with new text
        if ($('.carousel .liveRegion').length > 0) {
            $('.carousel .liveRegion').text(newContent);
        }

        // Update current dot
        $('.slick-dots li').removeAttr('aria-hidden').find('button').removeAttr('aria-label');
        $('.slick-dots li').eq(currentSlide).find('button').attr('aria-label', '(' + (currentSlide + 1) + ') Current')
    }

    function setCarouselInteracted($elem, isToggled) {
        $elem.attr('data-interacted', isToggled);
    }

    function isCarouselInteracted($elem) {
        return ($elem.attr('data-interacted') === true);
    }

    function updateSliderArrowVisibility(event, slick, nextSlide, $elem) {
        if (nextSlide === (slick.slideCount - slick.options.slidesToShow)) $elem.find('.slick-next').addClass('hidden')
        else $elem.find('.slick-next').removeClass('hidden');

        if (nextSlide === 0) $elem.find('.slick-prev').addClass('hidden');
        else $elem.find('.slick-prev').removeClass('hidden');
    }

    function hideCarouselArrows($elem) {
        $elem.find('.slick-arrow').addClass('hidden').attr('aria-disabled', 'true');
    }
});
function loadDiscoverCarouselResponsiveImage(loadFirstItemOnly) {
    var DATA_SELECTOR = "data-img-mobile";
    var CAROUSEL_RESPONSIVE_SELECTOR = ".hero-banner.hero-banner--uk";

    // If non-mobile
    if (isMobile() === false) {
        DATA_SELECTOR = "data-img-non-mobile";
    }

    $(CAROUSEL_RESPONSIVE_SELECTOR).each(function () {
        var $this = $(this);
        var imgURL = $this.attr(DATA_SELECTOR);

        if (imgURL === null || imgURL === "none") return false;

        $this.css('background-image', 'url(' + imgURL + ')');

        // If the first item is being loaded, break out the loop
        if (loadFirstItemOnly) return false;
    });
}

$(document).ready(function () {
    loadDiscoverCarouselResponsiveImage(true);
});

$(window).load(function () {
    loadDiscoverCarouselResponsiveImage(false);
});

function isMobile() {
    return ($(window).outerWidth() < 768);
}
