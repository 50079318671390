$(document).ready(function () {
    initVideoBanner();
})

var VIDEO_BANNER_ID = '.video-banner > #YTplayer';
var youtubePlayer;

function initVideoBanner() {
    if ($(VIDEO_BANNER_ID).length > 0) {

        // Pull in YouTubeAPI Script
        var tag = document.createElement('script');
        tag.id = 'youtubeAPI';
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
}

function onYouTubeIframeAPIReady() {
    var videoID = $(VIDEO_BANNER_ID).attr('data-video-id');

    // If no id, return
    if (typeof videoID === "undefined") return false;

    youtubePlayer = new YT.Player('YTplayer', {
        videoId: videoID,
        width: "100%",
        height: "100%",
        playerVars: {
            "autoplay": 1,
            "controls": 0,
            "rel": 0,
            "showinfo": 0
        },
        events: {
            'onStateChange': onPlayerStateChange
        }
    });
}


function onPlayerStateChange(e) {
    // If the video has ended restart it.
    if (e.data === YT.PlayerState.ENDED) {
        youtubePlayer.playVideo(); 
    }
}
