(function($) {
    var LIGHTBOX_CLASS = 'img.lightbox';
    var LIGHTBOX_MODAL = '#modal';

    $(document).ready(function() {
        $('body').on('click', LIGHTBOX_CLASS, onLightboxImageClick);
    });

    function showModal() {
        $("body .modal").addClass('lightbox-container');
        window.setTimeout(function () {
            $("body .modal").addClass('is-active');
        }, 0);
    }

    function hideModal() {
        $("body .modal").removeClass('lightbox-container').removeClass('is-active');
    }

    function isEpiserverEditMode() {
        return (window.location.href.indexOf('EPiServer') !== -1);
    }

    function isTouchDevice() {
        return 'ontouchstart' in document.documentElement;
    }

    function onLightboxImageClick(e) {
        // Ensure we are not viewing when in CMS
        if (isEpiserverEditMode()) { return; }

        // Grab image data
        var $img = $(this);
        var imgAlt = $img.attr('alt');
        var imgSrc = $img.attr('src');

        // Check to see if we are a touch device, if so open in a new tab
        if (isTouchDevice()) {
            // Open in a new tab
            window.open(imgSrc, this.target, "resizable,scrollbars,status");
            return;
        }

        // Create a clone of the image
        var $copy = $('<img>');
        $copy.attr('title', $img.attr('title'));
        $copy.attr('src', $img.attr('src'));
        $copy.attr('alt', $img.attr('alt'));
        $copy.removeClass('lightbox');

        // position the image in the center of the screen
        $copy.css({
            'position': 'absolute',
            'top': $img.offset().top - $(window).scrollTop() + $img.innerHeight() / 2,
            'left': $img.offset().left - $(window).scrollLeft() + $img.innerWidth() / 2,
            'max-width': $img.innerWidth(),
            'max-height': $img.innerHeight()
        });

        var $altText = $('<a class=\'lightbox-alt-text\' target=\'_blank\' href=\'' + imgSrc + '\'>(view full size image)</a>');

        $(LIGHTBOX_MODAL).html('');
        $altText.appendTo(LIGHTBOX_MODAL);
        $copy.appendTo(LIGHTBOX_MODAL);

        $(LIGHTBOX_MODAL).on('click', function () {
            var $that = $(this);
            // $(this).addClass('is-closing');
            window.setTimeout(function () {
                hideModal();
            }, 200);
        });

        showModal();
    }
})(jQuery);
