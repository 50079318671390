
// Plugin that runs on a tab change, this checks the new tab content and 
// if it finds equalised items it will run the equalise plugin
var TAB_SELECTOR = '.tabs a[data-toggle]';
var EQUALISE_SELECTOR = '[data-equalise]';
var TAB_EVENT_TRIGGER = 'shown.bs.tab';

$(document).ready(function () {

    $(TAB_SELECTOR).on(TAB_EVENT_TRIGGER, function (e) {

        var targetID = $(e.target).attr('href');
        var $targetContent = $(targetID);
        if (typeof $targetContent == "undefined" || $targetContent.length <= 0) return;

        // Check whether new tab has any equalise items
        if ($targetContent.find(EQUALISE_SELECTOR).length > 0) {
            if (typeof window.equalise === "object" && typeof window.equalise.init === "function") {
                window.equalise.init();
            }
        } 
    })
});