/**
 * @fileOverview Allows deep linking to content within Bootstrap components using #links
 * @author Phil Hazelton [phil@appius.com]
 * @version 1.1.0
 */
var appius = window.appius || {};

appius.deepAnchors = (function ($) {
    'use strict';
    var module = {};

    $(window).on('hashchange', function () {
        init();
    });

    function init() {
        var hash = location.hash;
        if (!hash) return false;

        var $elem = $(hash);
        if ($elem.length == 0) return false;

        var $container = findParentComponent($elem);
        if (!$container) return false;

        $container.on('shown.bs.tab', function () {
            scrollToElement($elem);
        }).tab('show');

        var $tabpanel = $container.next('[role="tabpanel"]');

        if ($tabpanel.length > 0) {
            $tabpanel.on('shown.bs.collapse', function () {
                scrollToElement($elem);
            }).collapse('show');
        }
    }

    /**
     * Find the component wrapping a particular element.
     * @param {object} $elem - The element to begin searching upwards from.
     * @returns {?object} - The wrapping component, or null if no component is found.
     */
    function findParentComponent($elem) {
        var $container = $elem.parents('[role="tab"]');
        if ($container.length > 0) return $container;

        var $tabpanel = $elem.closest('[role="tabpanel"]');
        var id = $tabpanel.attr('id');

        $container = $('[role="tab"][href="#' + id + '"]');
        if ($container.length > 0) return $container;

        $container = $('[data-toggle="collapse"][href="#' + id + '"]');
        if ($container.length > 0) return $container;

        return null;
    }

    /**
     * Immediately scroll the window to a given element.
     * @param {object} $elem - The element to scroll to.
     * @returns {void}
     */
    function scrollToElement($elem) {
        var offset = $elem.offset().top;
        $('html').scrollTop(offset);
    }

    $(document).ready(init);
    return module;
}(jQuery));
