(function($) {
    $(document).ready(function() {
        if (typeof window.sectionId !== "undefined") {
            checkPrimaryNav();
            checkTertiaryNav();
            checkLeftNav();
        }
    });

    function checkTertiaryNav() {
        var $item = $('.tertiary').find('#tertiary-' + window.pageId);

        if ($item !== null) {
            var $firstAnchor = $item.find("a");
            if ($firstAnchor !== null) {
                $firstAnchor.addClass("tertiary__link--active");
            }
        }
    }

    function checkPrimaryNav() {
        var $item = $('.primary-nav').find('#primary-nav-' + window.sectionId + ', #primary-nav-' + window.pageId);

        if ($item !== null) {
            var $link = $item.find("> a.primary-nav__link");
            if ($link !== null) {
                $link.addClass("primary-nav__link--active");

                // Check for parents
                //$link.parents(".primary-nav__item").find("> .primary-nav__link").addClass("primary-nav__link--active");
            }

            handleTouchDevices()
        }
    }

    function handleTouchDevices() {

        
        if ("ontouchstart" in document.documentElement)
        {
            //early escape if iOS device
            if(window.navigator.userAgent.includes('Mac')){
                return;
            }
            var primaryLinkList = document.querySelectorAll('.primary-nav__list >.primary-nav__item.dropdown >.primary-nav__link.hidden-xs');
            var activeLink;
            primaryLinkList.forEach(function(link){
                link.addEventListener('click', function(event){
                    if(activeLink != link){
                        event.preventDefault();
                        activeLink=link;
                    }
                })
            })
            document.addEventListener("click", function(event){
                if (event.target != activeLink){
                    activeLink = null;
                }
            })
        }
    }

    function checkLeftNav() {
        var $item = $('.left-nav').find('#left-nav' + window.sectionId + ', #left-nav-' + window.pageId);

        if ($item !== null) {
            var $link = $item.find("> a.left-nav__link");
            if ($link !== null) {
                $link.addClass("left-nav__link--active");
                //
                // // Check for parents
                // $link.parents(".left-nav__item").find("> .left-nav__link:not(.left-nav__link--active)").addClass("left-nav__link--selected");
            }
        }
    }
})(jQuery);
