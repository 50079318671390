var DESKTOP_SEARCH_BTN = '.header-bottom .site-search__btn';
var DESKTOP_SEARCH_FIELD = '.header-bottom .site-search__input';

var TABLET_SEARCH_BTN = '.header-top .site-search__btn';
var TABLET_SEARCH_FIELD = '.header-top .site-search__input';

var MOBILE_SEARCH_BTN = '.primary-nav__search-wrapper .primary-nav__search-btn';
var MOBILE_SEARCH_FIELD = '.primary-nav__search-wrapper .primary-nav__search';

function searchRecords(fieldSelector) {
    var searchText = $(fieldSelector).val().trim();
    if (searchText) {
        var newLocation = getSearchResultsPath() + '?searchterm=' + encodeURIComponent(searchText);
        var searchKey = window.searchKey;
        if (searchKey) newLocation += '&searchkey=' + searchKey;
        window.location = newLocation;
    }
}

function getSearchResultsPath() {
    var path = window.resultsPageUrl;
    if (typeof path !== "undefined") return path;
    else throw new Error("Couldn't get search results page location");
}

function checkForSearchText($elem) {
    return ($(DESKTOP_SEARCH_FIELD).val().length > 0 && $(DESKTOP_SEARCH_FIELD).val() !== "");
}

$(document).ready(function () {
    $(DESKTOP_SEARCH_FIELD).on('blur', function (e) {
        var hasSearchText = checkForSearchText(DESKTOP_SEARCH_FIELD);

        if (!hasSearchText) {
            $('.main-content .header-bottom .site-search').removeClass('is-active');
        }
    });
    $(DESKTOP_SEARCH_BTN).on('click', function (e) {
        e.preventDefault();

        if ($(window).innerWidth() > 767) {
            var hasSearchText = checkForSearchText(DESKTOP_SEARCH_FIELD);

            if (hasSearchText) {
                searchRecords(DESKTOP_SEARCH_FIELD);
            } else {
                $('.main-content .header-bottom .site-search').toggleClass('is-active');
                $(DESKTOP_SEARCH_FIELD).focus();
            }
        } else {
            searchRecords(DESKTOP_SEARCH_FIELD);
        }
    });
    $(DESKTOP_SEARCH_FIELD).keypress(function (e) {
        var key = e.which;
        if (key === 13) {
            searchRecords(DESKTOP_SEARCH_FIELD);
            return false;
        }
    });

    $(TABLET_SEARCH_BTN).on('click', function (e) {
        e.preventDefault();
        searchRecords(TABLET_SEARCH_FIELD);
    });
    $(TABLET_SEARCH_FIELD).keypress(function (e) {
        var key = e.which;
        if (key === 13) {
            searchRecords(TABLET_SEARCH_FIELD);
            return false;
        }
    });

    $(MOBILE_SEARCH_BTN).on('click', function (e) {
        e.preventDefault();
        searchRecords(MOBILE_SEARCH_FIELD);
    });
    $(MOBILE_SEARCH_FIELD).keypress(function (e) {
        var key = e.which;
        if (key === 13) {
            searchRecords(MOBILE_SEARCH_FIELD);
            return false;
        }
    });
});
