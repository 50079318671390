var TAB_DROPDOWN_SELECT = '[name="tabs__dropdown-select"]';
var TAB_CONTAINER_SELECTOR = '.tabs__container';
var TAB_ITEM_SELECTOR = '.nav-pills li.item';
var TAB_ITEM_LINK_SELECTOR = TAB_ITEM_SELECTOR + " > a";
var MOBILE_THRESHOLD = 768;

$(document).ready(function() {
    responsiveTabs();

    $('body').on('change', TAB_DROPDOWN_SELECT, onTabDropdownChange);
    $('body').on('click', TAB_ITEM_LINK_SELECTOR, onTabSelect);
});

// Setup the resize event handler and debounce using a timer.
$(window).on('resize', function () {
    if (window.scrollTimer) { clearTimeout(window.scrollTimer); }
    window.scrollTimer = setTimeout(function () {
        responsiveTabs();
    }, 100);
});

// Grab the selected item and show its relevant tab.
function onTabDropdownChange() {
    var $this = $(this);
    var $selectedOpt = $this.find(':selected');
    $selectedOpt.tab('show');
}

function onTabSelect() {
    $(TAB_DROPDOWN_SELECT).val("").parent('li.active').removeClass('active');
}

function responsiveTabs() {

    // Loop through each tab container on the page
    $(TAB_CONTAINER_SELECTOR).each(function() {
        var $tabContainer = $(this);
        var $tabItems = $tabContainer.find(TAB_ITEM_SELECTOR);
        var $dropDown = $tabContainer.find(TAB_DROPDOWN_SELECT);

        var maxTabCount = 0;

        maxTabCount = calculateMaxItems($tabContainer);

        // If we can show all of the tabs, skip more processing
        if (maxTabCount === $tabItems.length) {
            $dropDown.addClass('hide');
            $dropDown.parent('.tabs-selector__wrapper').addClass('hide');
            return true;
        } else {
            $dropDown.removeClass('hide');
            $dropDown.parent('.tabs-selector__wrapper').removeClass('hide');
            createExcessDropdown($tabContainer, maxTabCount);
        }
    });
}

function isMobile() {
    return ($("body").innerWidth() < MOBILE_THRESHOLD);
}

// Calculate the maximum number of items that can be comforatablly shown without kicking onto another line
function calculateMaxItems($tabContainer) {
    var $tabItems = $tabContainer.find(TAB_ITEM_SELECTOR);
    var tabContainerWidth = $tabContainer.width();
    var $dropDown = $tabContainer.find(TAB_DROPDOWN_SELECT);

    // Remove select dropdown width as that needs to stay on one line too
    tabContainerWidth -= $dropDown.outerWidth(true);

    if (isMobile()) { return -1; } // If mobile we want to hide them all

    var tabIndex = 0;

    // Loop through each tab item
    $tabItems.each(function() {
        var $tab = $(this);

        $tab.removeClass('hide');

        tabContainerWidth -= $tab.outerWidth(true);

        // If we have used up the available space stop.
        if (tabContainerWidth <= 0) { return false; }
        else { tabIndex++; }
    });

    return tabIndex;
}

// Dynamically create the dropdown options using the excess tabs
function createExcessDropdown($tabContainer, countIndex) {
    var $excessTabs;

    // If we want to hide ALL items
    if (countIndex === -1) {
        $excessTabs = $tabContainer.find(TAB_ITEM_SELECTOR);
    } else {
        $excessTabs = $tabContainer.find(TAB_ITEM_SELECTOR + ':gt(' + (countIndex - 1) + ')');  // Remove one as it is zero index based
    }

    // If there are no excess tabs stop processing.
    if ($excessTabs.length <= 0) return;

    var opts = '';
    
    // If not on mobile, add select as an option
    if (countIndex !== -1) {
        opts += '<option value="">Select</option>';
    }

    $excessTabs.each(function() {
        var $anchor = $(this).find("a");
        var val = $anchor.attr('href');
        var txt = $anchor.text();

        // HTML to create the dropdown item
        opts += '<option data-toggle="pill" href="' + val + '" value="' + val + '">' + txt + '</option>';
        $(this).addClass('hide');
    });

    // Clear the current markup and add in the new options
    $('[name="tabs__dropdown-select"]').html("");
    $('[name="tabs__dropdown-select"]').html(opts);
}
