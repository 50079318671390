(function($) {

  window.dataLayer  = window.dataLayer || [];

  var COOKIE_DISCLAIMER = 'professionals-disclaimer';
  var COOKIE_PROFESSIONALS_LOCATION = 'professionals-location';
  var COOKIE_WMUK_LOCATION = 'Location';
  var COOKIE_AUDIENCE_TYPE = 'professionals-audience-type';
  var COOKIE_AUDIENCE_URL = 'professionals-audience-url';
  var cachedLocation;
  var cachedAudience;
  var modalWindowType;
  var dropdownOpen = false;
  var dropdownDetached = false;
  var professionalsBarDetached;
  
  $(document).ready(function() {

    var $professionalsModal = $('[data-professionals-modal]');
    var $professionalsBar = $('[data-professionals-bar]');
    var $modalTrigger = $('[data-professionals-page-link]');
    var $mobileNav = $('.mobile-nav__fullheight');
    var pageVisitorGroup = $('[data-visitor-group]').data('visitor-group');
    var pageVisitorGroupArray = pageVisitorGroup ? pageVisitorGroup.split('|') : [];
    var pageHasModalTrigger = $modalTrigger.length > 0;
    var isoCodes = [];

    this.querySelectorAll('#location-selector option').forEach(function (option){
      isoCodes.push(option.value);
    })

    // If the professionals modal markup is in page
    if ($professionalsModal.length > 0) {

      var mobileNavBarHTML = "<div class='professionals__bar-mobile-nav' data-bar-mobile><div class='professionals__bar-location-mobile professionals__text--medium' data-professionals-selected-location></div><div class='professionals__bar-audience-type-mobile professionals__text--medium' data-professionals-selected-audience-type></div></div>";      
      var cookies = getCookies();

      //check for pre-existing cookies that don't match the new ISO format, set the cookies based on users WMUK location then update the stored variable
      if (isoCodes.indexOf(cookies.location) < 0) {
        Cookies.set( COOKIE_PROFESSIONALS_LOCATION, cookies.wmukLocation, undefined);
        Cookies.set(COOKIE_DISCLAIMER, 'declined', undefined);
        cookies = getCookies();
      }
      
      if (!cookies.disclaimer) {
        if ($professionalsBar.length > 0) {
          $professionalsBar.remove();
        }

        modalWindowType = 'Modal';

        if (!pageHasModalTrigger) {
          $(document).trigger('triggerShowModalWindow');
        }
      } 
      else {
        var audienceTypeCookieObj = JSON.parse(cookies.audienceType);

        // show modal with alert
        if (pageVisitorGroup && !matchesVisitorGroup(audienceTypeCookieObj.value, pageVisitorGroupArray)) { 
          if ($professionalsBar.length > 0) {
            professionalsBarDetached = $professionalsBar.detach();
            dropdownDetached = true;
          }
          modalWindowType = 'Modal';
          
          populateSelectedDisplayValues(cookies.location, audienceTypeCookieObj.label);
          $('[data-professionals-modal-step="1"]').removeClass('active');
          $('[data-professionals-modal-step="0"]').addClass('active');
          if (!pageHasModalTrigger) {
            $(document).trigger('triggerShowModalWindow');
          }
          
        } else { //show top bar

          if (!pageHasModalTrigger) {
            if ($mobileNav.length > 0) {
              $mobileNav.prepend(mobileNavBarHTML);
            }
            modalWindowType = 'Dropdown';
            populateSelectedDisplayValues(cookies.location, audienceTypeCookieObj.label);
            $(document).trigger('triggerCloseModalWindow');
          } else {
            if ($professionalsBar.length > 0) {
              $professionalsBar.remove();
            }
            modalWindowType = 'Modal';
          }
        }
      }

      if (!pageHasModalTrigger) { // Init on page load

        init(cookies.location, cookies.audienceType, pageVisitorGroupArray, cookies.disclaimer);

      } else { // add event listener to modal trigger and init on page load

        $modalTrigger.on('click', function(e) {
          e.preventDefault();

          if (cookies.disclaimer) {
            var url = $('[data-audience-type-selector]').find('input[type="radio"][value="' + JSON.parse(cookies.audienceType).value + '"]').data('audience-url');
            if (url) {
              window.location.href = url;
            }
          } else {
            $(document).trigger('triggerShowModalWindow');
            gtmTrackOpenClose('Open');
          }
        })
        init(cookies.location, cookies.audienceType, pageVisitorGroupArray, cookies.disclaimer);
      }
    }
  });

  function init(locationCookie, audienceTypeCookie, pageVisitorGroupArray, disclaimerCookie) {

    
    var $document = $(document);
    var audienceId = '1';
    var rememberSelection = false;

    function setInitValues() {
      if (locationCookie) {
        $('[data-location-selector]').val(locationCookie).change();
      }
  
      if (audienceTypeCookie) {
        $('[data-audience-type-selector]').find('input[type="radio"][name="audience-group-' + audienceId + '"]').filter('[value="' + JSON.parse(audienceTypeCookie).value + '"]').prop('checked', true);
      }
    };

    // Accessibility - 'enter' key to trigger click on label to check/uncheck input
    $document.on('keypress', 'label', function(e) {
      if(e.which == 13) {
        $(this).trigger('click');
      }
    });
    
    $document.on('click', '[data-professionals-bar-container], [data-close-dropdown-mobile], [data-bar-mobile]', function() {
      toggleDropDown(audienceId);
    });

    function handleClose() {
      var selections = getSelections(audienceId);
      var targetURL = document.getElementById('professionalsModal').dataset.defaultProfessionalsLandingPage;
      gtmTrackOpenClose('Close');
      if (document.location.href.indexOf('/professionals/') === -1){
        $(document).trigger('triggerCloseModalWindow');
        return;
      }
    
      if (audienceTypeCookie && disclaimerCookie === true){
        targetURL = selections.audienceTypeUrl;
      }
      window.location.href = targetURL;
    }

    $document.on('click', '[data-close-modal]', function() {
      handleClose()
      if (dropdownDetached) {
        $('[data-professionals-modal]').remove();
        $(professionalsBarDetached).prependTo('.main-content');
        populateSelectedDisplayValues(locationCookie, JSON.parse(audienceTypeCookie).label);
        modalWindowType = 'Dropdown';
        setInitValues();
      }
    });

    $document.on('click', '[data-decline-cta]', function() {
      Cookies.set(COOKIE_DISCLAIMER, 'declined', rememberSelection ? { expires: 730 } : undefined);
      $(document).trigger('triggerCloseModalWindow');

      // GTM Tracking //
      gtmTrackDeclineDisclaimer(audienceId);
      //////////////////

      var redirectUrl = $(this).data('decline-cta');
      window.location.href = redirectUrl;
      
    });

    $document.on('click', '[data-accept-cta]', function() {
      Cookies.set(COOKIE_DISCLAIMER, 'accepted', rememberSelection ? { expires: 730 } : undefined);
      $(document).trigger('triggerCloseModalWindow');

      // GTM Tracking //
      gtmTrackAcceptDisclaimer(audienceId);
      //////////////////

      var selections = getSelections(audienceId);

      if (matchesVisitorGroup(selections.audienceTypeValue, pageVisitorGroupArray)) {
        location.reload();
      } else {
        var redirectUrl = selections.audienceTypeUrl;
        window.location.href = redirectUrl;
      }
    });

    $document.on('change', '[data-remember-selector]', function() {
      rememberSelection = $(this).is(':checked');
    })

    $document.on('change', '[data-location-selector]', function() {
      audienceId = $(this).find(':selected').data('audience-id');
      $('[data-audience-type-selector]').removeClass('active');
      $('[data-audience-type-selector="' + audienceId + '"]').addClass('active');

      if (modalWindowType === 'Dropdown' && dropdownOpen) {
        reloadWithNewSelections(audienceId, rememberSelection);
      }
    });

    $document.on('change', '[data-audience-type-selector] input[type="radio"]', function(e) {
      if (modalWindowType === 'Dropdown') {
        reloadWithNewSelections(audienceId, rememberSelection);
      }
    });

    $document.on('click', '[data-step-cta]', function(e) {

      e.preventDefault();
      var $this = $(this);
      var validation = requiredFieldsValid();

      // Clear any previous error msgs
      clearErrorMsgs();

      if (validation.valid) {
        var stepNo = $this.data('step-cta');
        if (stepNo === 2){
          gtmTrackSelection(audienceId);
        }
        $('[data-professionals-modal-step]').removeClass('active');
        $('[data-professionals-modal-step="' + stepNo + '"]').addClass('active');

        if ($this[0].hasAttribute('data-set-cookie')) {
          var selections = getSelections(audienceId);  
          writeCookies(selections.location, selections.audienceTypeValue, selections.audienceTypeLabel, rememberSelection);
        }
      } 
      else {
        validation.errorMsgs.forEach(function(el) {
          $(el).addClass('show');
        })
      }
    });

    setInitValues();
  };

  function matchesVisitorGroup(cookieAudienceValue, pageVisitorGroupArray) {
    var match = false;
    pageVisitorGroupArray.forEach(function(group) {
      if(group === cookieAudienceValue) {
        match = true;
      }
    });
    return match;
  };

  function getCookies() {
    var disclaimerCookie = Cookies.get(COOKIE_DISCLAIMER) === 'accepted';
    var locationCookie = Cookies.get(COOKIE_PROFESSIONALS_LOCATION);
    var audienceTypeCookie = Cookies.get(COOKIE_AUDIENCE_TYPE);
    var audienceUrlCookie = Cookies.get(COOKIE_AUDIENCE_URL);
    var wmukLocationCookie = Cookies.get(COOKIE_WMUK_LOCATION);

    return {
      disclaimer: disclaimerCookie,
      location: locationCookie,
      audienceType: audienceTypeCookie,
      audienceUrl: audienceUrlCookie,
      wmukLocation: wmukLocationCookie
    }
  };

  function populateSelectedDisplayValues(location, audienceType) {
    var locationString = document.querySelector('#location-selector [value="' + location + '"]').textContent;
    $('[data-professionals-selected-location]').text(locationString);
    $('[data-professionals-selected-audience-type]').text(audienceType);
  };

  function toggleDropDown(audienceId) {
    var $this = $('[data-professionals-bar-container]');
    
    if ($this.hasClass('active')) {
      dropdownOpen = false;
    } else {
      var selections = getSelections(audienceId); 
      cachedLocation = selections.location;
      cachedAudience = selections.audienceTypeValue;
      dropdownOpen = true;
      gtmTrackOpenClose('Open');
    }

    $this.toggleClass('active');
  };

  function reloadWithNewSelections(audienceId, rememberSelection) {
    var validation = requiredFieldsValid();

    if (validation.valid) {
      var selections = getSelections(audienceId);  
      if (selections.location !== cachedLocation || selections.audienceTypeValue !== cachedAudience) {
        writeCookies(selections.location, selections.audienceTypeValue, selections.audienceTypeLabel, rememberSelection);
        gtmTrackSelection(audienceId);
        location.reload();
      } 
    } 
    else {
      validation.errorMsgs.forEach(function(el) {
        $(el).addClass('show');
      })
    }
  };

  function clearErrorMsgs() {
    $('[data-validation-error]').each(function() {
      $(this).removeClass('show');
    })
  };

  function getSelections(audienceId) {
    var location = $('[data-location-selector]').find(':selected').val();
    var audienceTypeField = $('[data-audience-type-selector]').find('input[type="radio"][name="audience-group-' + audienceId + '"]:checked');

    return {
      location: location,
      audienceTypeValue: audienceTypeField.val(),
      audienceTypeLabel: audienceTypeField.data('audience-value'),
      audienceTypeUrl: audienceTypeField.data('audience-url')
    };
  };

  function writeCookies(location, audienceTypeValue, audienceTypeLabel, persistent) {
    Cookies.set(COOKIE_PROFESSIONALS_LOCATION, location, persistent ? { expires: 730 } : undefined);
    Cookies.set(COOKIE_AUDIENCE_TYPE, { value : audienceTypeValue, label: audienceTypeLabel }, persistent ? { expires: 730 } : undefined);
    if(location === 'GB' || location === 'JE' || location === 'IM' || location === 'GG') {
      Cookies.set(COOKIE_WMUK_LOCATION, location, persistent ? { expires: 730 } : undefined)
    }
  };

  function requiredFieldsValid() {
    var valid = true;
    var $radioGroups = $('[data-validate-radio-group]:visible');
    var $checkboxes = $('[data-validate-checkbox]:visible');
    var errorMsgs = [];

    // validate radio groups
    $radioGroups.each(function() {
      var $this = $(this);
      var name = $this.find('input').attr('name');
      var errorMsg = $this.find('[data-validation-error]');
      var checked = $('input:radio[name="' + name + '"]').is(':checked');
      if(!checked) {
        valid = false;
        errorMsgs.push(errorMsg);
      } 
    })

    $checkboxes.each(function() {
      var $this = $(this);
      var checked = $this.find('input:checkbox').is(':checked');
      var errorMsg = $this.find('[data-validation-error]');
      if(!checked) {
        valid = false;
        errorMsgs.push(errorMsg);
      } 
    })

    return {
      valid: valid,
      errorMsgs: errorMsgs
    }
  };

  function pushToDataLayer(action, location, type) {
    window.dataLayer.push({
      'event': 'modal_interaction',
      'modal_type': 'Intermediaries ' + modalWindowType,
      'modal_action': action,
      'professionals_location': location || undefined,
      'professionals_type': type ||undefined
     });
  };

  function gtmTrackSelection(audienceId) {
    var selections = getSelections(audienceId);
    var modalAction = modalWindowType === 'Modal' ? 'details selected' : 'details updated';
    pushToDataLayer(modalAction, selections.location, selections.audienceTypeLabel);
  };

  function gtmTrackAcceptDisclaimer(audienceId) {
    var selections = getSelections(audienceId);
    pushToDataLayer('accept_selection', selections.location, selections.audienceTypeLabel);
  };

  function gtmTrackDeclineDisclaimer(audienceId) {
    var selections = getSelections(audienceId);
    pushToDataLayer('decline_selection', selections.location, selections.audienceTypeLabel);
  };

  function gtmTrackOpenClose(action) {
    pushToDataLayer(action);
  }

})(jQuery);
