var LANGUAGE_SELECTOR = '.language-selector';
var LANGUAGE_SELECTOR_ACTIVE = 'is-active';

$(document).ready(function() {
    $('body').on('click', '.language-selector',  onLanguageClick);
    $('body').on('click', onBodyClick);
});

function onLanguageClick(e) {
    $(this).toggleClass('is-active');
    e.stopPropagation();
}

function onBodyClick(e) {
    if (e.target !== LANGUAGE_SELECTOR) {
        if ($(LANGUAGE_SELECTOR + '.' + LANGUAGE_SELECTOR_ACTIVE).length > 0) {
            $(LANGUAGE_SELECTOR).removeClass(LANGUAGE_SELECTOR_ACTIVE);
        }
    }
}