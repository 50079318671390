var ITEM_SELECTOR = '#back-to-top';

$(document).ready(function() {
    var oldScrollPosition = 0;

    var scrollBounce = debounce(function() {
        var newPosition = $(window).scrollTop();
        if (newPosition > oldScrollPosition && newPosition > 50)
            $(ITEM_SELECTOR).fadeIn();
        else
            $(ITEM_SELECTOR).fadeOut();

        oldScrollPosition = $(window).scrollTop();
    }, 250);

     $(window).scroll(function () {
        scrollBounce();
    });

    $(ITEM_SELECTOR).on('click', function (e) {
        e.preventDefault();
        $(ITEM_SELECTOR).tooltip('hide');
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
});

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
