(function ($) {
	var INFORMATION_COOKIE_NAME = "information-modal";
	var INFORMATION_MODAL_SELECTOR = '.information-modal';
	var INFORMATION_MODAL_ALWAYS_ATTRIBUTE = "always-show";
	var CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME = "custom-expiry-information-modal";
	var CUSTOM_EXPIRY_DURATION_ATTRIBUTE = "custom-expiry-duration-in-hours";
	var EXPIRY_DURATION_CHANGED = "expiry-duration-changed";
	var dateNow = new Date();
	// custom-expiry
	$(document).ready(function () {
		// If the modal markup is in page
		if ($(INFORMATION_MODAL_SELECTOR).length > 0) {

			if (!$(INFORMATION_MODAL_SELECTOR)[0].hasAttribute('data-override-default')) {
				initialiseModal();
			}

			$('.modal').on('click', '.accept', function (e) {
				e.preventDefault();
				acceptModal();
				$('.information-modal').remove();
			});

			$(document).on('triggerCloseModalWindow', function () {
				$('.information-modal').remove();
				hideModal();
			});

			$(document).on('triggerShowModalWindow', function () {
				showModal();
			});
		}
	});

	function initialiseModal() {
		// Check if cookie exists, if so do nothing
		if (isEpiserverEditMode() === false && (alwaysShowModal() || checkCookieExists() === false)) {
			dropCookie(INFORMATION_COOKIE_NAME);
			dropCookie(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME);
			showModal();
		} else if (
			// set custom expiry cookie
			isEpiserverEditMode() === false && alwaysShowModal() === false && checkCookieExists() === true
			&& expiryDurationHoursLeft() < dateNow
			// update existing cookie to custom expiry cookie
			|| (isEpiserverEditMode() === false && Cookies.get(INFORMATION_COOKIE_NAME) === "accepted" && isCustomExpiryCookie())) {

			dropCookie(INFORMATION_COOKIE_NAME);
			dropCookie(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME);
			showModal();
		} else if (isEpiserverEditMode() === false && alwaysShowModal() === false && isCustomExpiryCookie()
			&& checkCookieExists() === true && Cookies.get(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME) === "accepted" &&
			$(INFORMATION_MODAL_SELECTOR).data(EXPIRY_DURATION_CHANGED) === 'True') {
			// update custom expiry cookie expiry duration hours left, if we already have accepted custom expiry cookie
			dropCookie(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME);
			setCookie(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME, expiryDurationHoursLeft());
			$('.information-modal').remove();
			hideModal();
			return;
		} else if (isEpiserverEditMode() === false && alwaysShowModal() === false && isCustomExpiryCookie() === false
			&& checkCookieExists() === true && Cookies.get(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME) === "accepted") {
			// drop custom expiry cookie when expiry duration hours left = 0
			dropCookie(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME);
			showModal();
		} else {
			$('.information-modal').remove();
			hideModal();
			return;
		}
	}

	function expiryDurationHoursLeft() {
		var date = new Date(dateNow);
		var expiryDuration = $(INFORMATION_MODAL_SELECTOR).data(CUSTOM_EXPIRY_DURATION_ATTRIBUTE);
		var expiryDurationMilliseconds = expiryDuration * 3600 * 1000;
		var utcDate = Date.UTC(
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate(),
			date.getUTCHours(),
			date.getUTCMinutes(),
			date.getUTCSeconds(),
			date.getUTCMilliseconds()
		);
		var expiryUtcMilliseconds = utcDate + expiryDurationMilliseconds;
		return new Date(expiryUtcMilliseconds);
	}

	function alwaysShowModal() {
		return $(INFORMATION_MODAL_SELECTOR).data(INFORMATION_MODAL_ALWAYS_ATTRIBUTE) === "True";
	}

	// Read the cookie data and return if value set
	function checkCookieExists() {
		return (Cookies.get(INFORMATION_COOKIE_NAME) === "accepted") || (Cookies.get(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME) === "accepted");
	}

	function isCustomExpiryCookie() {
		return (alwaysShowModal() === false && expiryDurationHoursLeft() > dateNow);
	}

	function writeCookie() {
		if (isCustomExpiryCookie() === false) {
			setCookie(INFORMATION_COOKIE_NAME, 730);
			dropCookie(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME);
		} else {
			setCookie(CUSTOM_EXPIRY_INFORMATION_COOKIE_NAME, expiryDurationHoursLeft());
			dropCookie(INFORMATION_COOKIE_NAME);
		}
	}

	function acceptModal() {
		writeCookie();
		hideModal();
	}

	function showModal() {
		var htmlContents = $(".information-modal__inner").html();
		if ($(".information-modal").data("always-show") === undefined) {
			$('.information-modal').remove();
		}
		// $("body").addClass("modal--active");
		$("body .modal__inner").eq(0).html(htmlContents);
		$("body .modal").modal('show');
	}

	function hideModal() {
		$("body .modal").modal('hide');
	}

	function isEpiserverEditMode() {
		return (window.location.href.indexOf('EPiServer') !== -1);
	}

	function setCookie(cookieName, expires) {
		Cookies.set(cookieName, "accepted", { expires: expires, path: '/' });
	}

	function dropCookie(cookieName) {
		Cookies.set(cookieName, "declined", { expires: 0, path: '/' });
	}
})(jQuery);
