var hasErrors = true;
var onloadCallback = function () {
	grecaptcha.render('dvCaptcha', {
		'sitekey': reCaptchaSiteKey,
		'callback': function (responseData) {
			var sendInfo = { response: responseData };
			$.ajax({
				type: "POST",
				url: reCaptchaUrlEndPoint,
				data: JSON.stringify(sendInfo),
				contentType: "application/json; charset=utf-8",
				dataType: "json",
				success: function (r) {
					var captchaResponse = jQuery.parseJSON(r.Response);
					if (captchaResponse.success) {
						hasErrors = false;
					} else {
						hasErrors = true;
					}
				},
				error: function (e) {
					console.log(e);
				}
			});
		}
	});
};

function validateRecaptcha() {
	var response = grecaptcha.getResponse();
	hasErrors = false;

	if (response.length == 0) {
		hasErrors = true;
	}

	if (hasErrors) {
		$("#captchaError").show();
	} else {
		$("#captchaError").hide();
	}
}
